@font-face {
  font-family: televideo-regular;
  src: url("./fonts/televideo_regular.otf");
}

@font-face {
  font-family: televideo-reverse;
  src: url("./fonts/televideo_reverse.otf");
}

:root {
  --clr-3r-purple: #9637d9;
  --clr-3r-green: #a52ef5;
  --clr-logo: #a846e9;
}

body {
  background-color: black;
  /* background-color: rgb(210, 255, 210); */
}

.App {
  text-align: center;
}

header {
  position: fixed;
  top: 0;
  width: 98%;
  margin-top: 10px;
  /* border-style: solid; */
}

.social-icon {
  margin-left: 92%;
  margin-top: 2%;
  color: white;
}

h1,
h2,
h3 {
  /* color: var(--clr-logo); */
  color: white;
  font-family: televideo-regular;
}

h1.logo {
  font-size: 136px;
  letter-spacing: -8px;
  margin: 0;
  padding: 0;
}

h2.subheading {
  font-size: 6vw;
  margin: 0;
  padding: 0;
  letter-spacing: 3px;
}

.container {
  margin-top: 36vh;
  display: flex;
  align-items: center;
  justify-content: center;
  /* height: 100vh; */
  /* -webkit-user-select: none;
  -webkit-touch-callout: none;
  -moz-user-select: none;
  -ms-user-select: none; */
}

.typewriter h1 {
  overflow: hidden; /* Ensures the content is not revealed until the animation */
  border-right: 0.14em solid white; /* The typwriter cursor */
  white-space: nowrap; /* Keeps the content on a single line */
  margin: 0 auto; /* Gives that scrolling effect as the typing happens */
  letter-spacing: 0.04em; /* Adjust as needed */
  animation: typing 0.65s steps(7, end), blink-caret 0.9s step-end infinite;
}

/* The typing effect */
@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* The typewriter cursor effect */
@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }
  50% {
    border-color: #b756f8;
    border-color: white;
  }
}

@media only screen and (max-width: 462px) {
  h1.logo {
    font-size: 78px;
    letter-spacing: 0px;
    margin: 0;
    padding: 0;
  }

  .typewriter h1 {
    overflow: hidden; /* Ensures the content is not revealed until the animation */
    border-right: 0.12em solid var(--clr-logo); /* The typwriter cursor */
    white-space: nowrap; /* Keeps the content on a single line */
    margin: 0 auto; /* Gives that scrolling effect as the typing happens */
    letter-spacing: 0.04em; /* Adjust as needed */
    animation: typing 0.65s steps(7, end), blink-caret 0.9s step-end infinite;
  }

  /* The typing effect */
  @keyframes typing {
    from {
      width: 0;
    }
    to {
      width: 100%;
    }
  }

  .social-icon {
    margin-left: 85%;
  }
}
